import { useEffect, useState } from "react";

export default function homepageStaticReview(props) {
    const { t } = props;

    return (
        <>
            <section className="homepage-sec py-1">
                    <div className='row justify-content-center'>
                        <div className='col-lg-12 col-sm-5 col-5 text-center p-0 p-lg-1'>
                            <a href='https://www.trustpilot.com/review/ugrado.com'>
                                <img src='/assets/images/homepage/reviews-trustpilot.png' alt="" className="img-fluid w-100" />
                            </a>
                            {/* <div>{`>> trustpilot logo here <<`}</div>
                            <div>{`>> trustpilot star rating here <<`}</div>
                            <div>Based on 500 Trustpilot reviews</div>
                            <div className='fw-bold' style={ { color: '#e0a61c' } }>{`>> trustpilot scoring here <<`}</div> */}
                        </div>
                        <div className='col-lg-12 col-sm-5 col-5 text-center p-0 p-lg-1'>
                            <a href='https://www.sitejabber.com/reviews/ugrado.com'>
                                <img src="/assets/images/homepage/reviews-sitejabber.png" alt="" className="img-fluid w-100" />
                            </a>
                            {/* <div>{`>> sitejabber logo here <<`}</div>
                            <div>{`>> sitejabber star rating here <<`}</div>
                            <div>Based on 700 sitejabber reviews</div>
                            <div className='fw-bold' style={ { color: '#e0a61c' } }>{`>> sitejabber scoring here <<`}</div> */}
                        </div>
                    </div>
            </section>
        </>
    )
}