import { useEffect, useState } from "react";
import { _postURL } from "@/api/index";
import { Link } from "@/helpers/common_helper";
import Redux, { updatePath } from "@/action"
import _ from 'lodash';
import { useForm } from "@mantine/form";
import { Anchor, Button, TextInput, UnstyledButton } from "@mantine/core";
import { toast } from "@/libraries/swal";
import { useTranslation } from "next-i18next";
import HomepageStaticReviewLatest from "@/components/section/homepageStaticReview";

export default function Subscription() {
    const { t } = useTranslation();
    const { store, dispatch } = Redux();
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(async () => {
        /*
        if (!store.path.contactIcon) {
            try {
                let result = await _postURL("/api/contact");
                dispatch(updatePath({ key: "contactIcon", path: result.data ?? [] }));
            }
            catch (error) {
                console.log(error);
            }
        }
        */
    }, []);

    const { getInputProps, onSubmit, isValid } = useForm({
        validateInputOnBlur: true,
        validateInputOnChange: true,
        initialValues: {
            email: ''
        },
        validate: {
            email: (inputValue) => !(/^\S+@\S+$/.test(inputValue)) && 'Invalid email',
        }
    });

    const subscribeNewsleter = async (formData) => {
        setIsProcessing(true);
        console.log(formData);
        
        try {
            const response = await _postURL("/api/newsletter/subscribe", { ...formData });

            await toast.success('Subscribed!');

        } catch (error) {
            toast.error(error)

        } finally {
            setIsProcessing(false);
        }
    };

    return (<>
        <section className="call-action-sec">
            <div className="container d-none d-xl-block">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="call-action-left">
                            <h4>Be the first to get news and update about the {t('Site_name')}</h4>
                            <form onSubmit={onSubmit((values) => subscribeNewsleter(values))}>
                                <input type="text" className="form-control" placeholder="Your Email" { ...getInputProps('email') } />
                                <Button
                                    loading={isProcessing}
                                    loaderPosition="center"
                                    component="button"
                                    type="submit"
                                    classNames={{
                                        root: 'call-action-left-button'
                                    }}
                                    sx={{
                                        ":disabled": {
                                            opacity: .7,
                                            backgroundColor: '#bb9b6a',
                                            color: '#fff'
                                        },
                                        ":hover": {
                                            backgroundColor: '#bb9b6a',
                                        }
                                    }}
                                    disabled={!isValid()}
                                >Subscribe</Button>
                            </form>
                            <p>{t('Site_name')} will not disclose your personal data to third parties, with exceptions of any of our subsidiaries, or
                                servicing affiliates. You will receive promotional emails from us upom subscription. By subscribing, you 
                                agreed and acknowledged our terms of use and privacy policy. Learn more about the terms here.</p>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2">
                        <h4>Review us</h4>
                        <HomepageStaticReviewLatest />
                    </div>
                    <div className="col-lg-3">
                        <div className="follow-us">
                            <h4>Follow us</h4>
                            <div className="row row-cols-5 py-2">
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://www.facebook.com/profile.php?id=100084059957881"><div className="social-media-facebook"></div></a>
                                </div>
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://www.instagram.com/ugrado_official/"><div className="social-media-instagram"></div></a>
                                </div>
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://www.pinterest.com/ugrado_official/"><div className="social-media-pinterest"></div></a>
                                </div>
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://www.tiktok.com/@ugrado"><div className="social-media-tiktok"></div></a>
                                </div>
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://twitter.com/ugrado_com"><div className="social-media-twitter"></div></a>
                                </div>
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://www.youtube.com/channel/UCQkeDO7V8gWVY-15ARoxUfQ"><div className="social-media-youtube"></div></a>
                                </div>
                                <div className="col py-2">
                                    <a target={"_blank"} rel="nofollow" href="https://t.me/ugrado"><div className="social-media-telegram"></div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom_container2 pt-5 d-block d-xl-none">
                <div className="call-action-mobile">
                    <h3>Be the first to get news and update about the {t('Site_name')}</h3>
                    <form onSubmit={onSubmit((values) => subscribeNewsleter(values))}>
                        <div className="row">
                            <div className="col-12">
                                {/* <TextInput 
                                    type="email"
                                    name="email"
                                    placeholder="Your Email"
                                    className="form-control"
                                    { ...getInputProps('email') }
                                /> */}
                                <input type="text" className="form-control px-4" placeholder="Your Email" { ...getInputProps('email') } />
                            </div>
                            <div className="col-12">
                                <Button
                                    loading={isProcessing}
                                    loaderPosition="center"
                                    type='submit'
                                    disabled={!isValid()}
                                >Subscribe</Button>
                            </div>
                            {/* <div className="col-12"><button>Subscribe</button></div> */}
                        </div>
                    </form>
                    <p>{t('Site_name')} will not disclose your personal data to third parties, with exceptions of any of our subsidiaries, or
                        servicing affiliates. You will receive promotional emails from us upom subscription. By subscribing, you 
                        agreed and acknowledged our terms of use and privacy policy. Learn more about the terms here.</p>
                </div>
            </div>
        </section>
        {/* <section className="call-action-sec d-none d-xl-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="call-action-left">
                            <h4>Be the first to get news and update about the {t('Site_name')}</h4>
                            <form>
                                <input type="text" className="form-control" placeholder="Your Email" />
                                <Link href="#">Subscribe</Link>
                            </form>
                            <p>{t('Site_name')} will process your personal data to (i) manage your subscription (ii) and measure
                                the performance of our campaigns and analyze your
                                interactions with our communications. Your data can be shared with others {t('Site_name')} affiliate
                                in order to know you better and with your consent
                                sending you marketing offers. You can manage your communication preferences at any time
                                or unsubscribe using the link included into all
                                our emails. To exercise your Privacy rights: privacy.tp@{t('Site_name')}.com. Learn more about 
                                how {t('Site_name')} manage your data here.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="follow-us">
                            {store.path.contactIcon && store.path.contactIcon.length > 0 ? <h4>{t('Follow_us')}</h4> : ''}
                            <ul>
                                {store.path.contactIcon &&
                                    _.map(store.path.contactIcon, c => (
                                        <li key={c.contact_id}>
                                            <Link href={c.link}><a target="_blank"><img src={process.env.NEXT_PUBLIC_FS_URL+c.image_path} alt="" className="img-fluid" /></a></Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </>)
}